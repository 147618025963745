import React, { Fragment } from "react";

import Page from "lib/@cms/components/layout/Page";
import Feature from "lib/@cms/components/cms/Feature";
import Divider from "lib/@cms/components/primitive/Divider";
import PageHeader from "lib/@cms/components/cms/PageHeader";
import Text from "lib/@cms/components/shared/Text";

function SavingsAccounts() {
  return (
    <Page pathname="savings-accounts">
      {data => {
        return (
          <React.Fragment>
            <PageHeader
              variant={PageHeader.variant.GRADIENTS}
              color={PageHeader.color.PRIMARY}
              data={data.PageHeaderGradient[0]}
            />
            <Container>
              <Divider className="tw-mb-6" />
              <Text variant={Text.variant.BODY}>{data.MetaData.description}</Text>
              <Divider className="tw-mt-12 tw-mb-20 md:tw-mb-28 tw-border tw-border-grayscale-6" />
              {data.FeaturesV2?.map((feature, index) => {
                return (
                  <Fragment key={`FeatureV2-${index}`}>
                    <Feature variant={Feature.variant.V2} data={feature} />

                    <Divider className="tw-mb-20 md:tw-mb-40" />
                  </Fragment>
                );
              })}
            </Container>
          </React.Fragment>
        );
      }}
    </Page>
  );
}

export default SavingsAccounts;

// --- Components ---

function Container({ children, className }) {
  return (
    <div className={`tw-w-full ${className}`}>
      <div className="tw-pt-18 tw-rounded-xl">
        <div className="tw-container">{children}</div>
      </div>
    </div>
  );
}
